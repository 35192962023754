import { comFormat, setDateTime } from "js/function";
import { useNavigate } from "react-router-dom";
import {ChkBox} from "../basic/formItems";
import React from "react";

function CouponItemBox(data){

    return (
        <div className={`couponItemBox ${data?.addClass ? data?.addClass : ''}`}>
            {data.children}
        </div>
    );
}

function CouponItem(data){
    let navigate = useNavigate();

    return (
        <div className={`couponItem ${data.addClass ? data.addClass : ""}`}>
            {data?.checkbox && (
                <div className="checkbox">
                    <ChkBox
                        addClass="allChkItem"
                        func={(e) => { if (!data.coupons?.some(item => item?.id === data?.id)) { data.setCoupons(prev => [...prev, data]); } else { data.setCoupons(prevItems => prevItems.filter(item => item.id !== data?.id)) } }}
                        checkedType={!!data.coupons?.some(item => item?.id === data?.id)}
                        label=""
                    />
                </div>
            )}
            <div>
                {data?.closeButton ? <button type="button" className="btnClose btnHeaderRight top" onClick={(e) => {data.closeFunc(data?.id)}}><img src="/assets/images/basic/close_w.svg"/></button> : ""}
                <p className="coupon_cost gTextColor">{data.type == "price" ? `${comFormat(data.value)}원` : `${comFormat(data.value)}%`}</p>
                <h1 className="coupon_name" dangerouslySetInnerHTML={{__html:data.name}}/>
                <p className={`coupon_status ${data.status == "0" ? "" : "end"}`}>{data.status == "0" ? "사용가능" : "사용불가"}</p>
                {data?.start_date && (<p className="coupon_date">{`사용기간 : ${data.start_date && data.start_date !== "0000-00-00" ? setDateTime(data.start_date, "yymmdd", "hymd") : ""}${data.start_date && data.start_date !== "0000-00-00" && data.end_date && data.end_date !== "0000-00-00" ? " ~ " : ""}${data.end_date && data.end_date !== "0000-00-00" ? setDateTime(data.end_date, "yymmdd", "hymd") : ""}`}</p>)}
            </div>
        </div>
    );
}

export {CouponItemBox, CouponItem};